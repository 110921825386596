.add-font-form{
    .modal-content {
        max-height: max-content;
        position: relative;
        .modal-body {
            overflow-y: visible;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .has-error .Select-control {
        border-color: #d24a35;
    }
    .modal-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 3;
    }

    .terms-condition {
        .terms-text {
            height: 100px;
            overflow: auto;
            border: solid 1px #c4cdd6;
            padding: 5px;
        }
    }

    .file-dropzone {
        height: 150px;
    }
}
