.no-results {
    margin: 40px auto 10px;
}

.fontlist-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}

.tech-section {
    background-color:#eff3f5;
    width: 100%;
    border: 1px solid #c4cdd6;
    border-radius: 0 4px 4px 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    margin: 25px 0;
    padding: 5px 8px 5px 8px;
    position: relative;
    .tech-title {
        position: absolute;
        height: 35px;
        top: -35px;
        left: -1px;
        font-size: 16px;
        border: 1px solid #c4cdd6;
        border-bottom: none;
        border-radius: 0px 6px 0px 0px;
        min-width: 100px;
        padding: 5px 20px;
        background-color:#eff3f5;
        text-align: center;
    }
}
