.btn.btn-default.search-clear-button {
    position: absolute;
    right: 38px;
    height: 50px;
    top: 0;
    background: transparent;
    path:not([fill="none"]) {
        fill: #0088a9;
    }

    border: none;
    &:hover,
    &:active:hover,
    &:focus {
        background: transparent;
        border: none;
        color: transparent;
        outline: none;
    }
}
.search-clear-button, .search-button {
    z-index: 3 !important;
}
.search-container__search-value {
    .form-group.form-group-active input {
        padding-right: 30px;
    }
    .input-group-btn>.btn {
        padding: 14px 12px;
    }
    .search-text {
        width: 300px;
    }
}
