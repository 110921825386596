.textcombo-selector {
    display: flex;

    .text-box {
        width: 450px;
        .input-group {
            z-index: 1000 !important;
        }
        input {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .input-group-btn .btn-group {
            margin-left: -1px;
            .dropdown-toggle {
                height: 48px;
                span.caret {
                    margin: 0 12px 0 4px;
                }
            }
            .dropdown-menu {
                margin: 2px -400px;
                width: 450px;
            }
            .dropdown-menu > li > button {
                font-weight: inherit;
            }
            .btn:hover {
                border: unset;
            }
        }

    }
}
