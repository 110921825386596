.catalog__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    .fonts-toggle {
        line-height: 50px;
    }
    .repository {
        display: flex;
        .create-repo-name {
            padding: 9px;
        }
        .dropdown-menu {
            width: 350px;
            max-height: 300px;
            overflow: auto;
        }
        .dropdown-menu>li>a {
            padding: 12px 23px;
        }
        .repository__dropdown {
            width: 350px;
            .dropdown-toggle {
                height: 50px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                padding-right: 30px;
                text-align: left;
            }
        }
    }
}

.repository-dropdown {
    flex-basis: 25%;
}

.container.landing {
    width: 98vw;
}

.action-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    .form-group {
        margin-bottom: 0;
    }
}
.catalog-spinner {
    position: absolute;
    left: 48%;
    top: 48%;
    z-index: 3;
}
.dummy-preview {
    display: none;
}

.family-label {
    display: flex;
    flex-basis: 40%;
}
.action-hidden {
    visibility: hidden;
    flex-basis: 8%;
}

.text-manipulate-actions {
    flex-basis: 8%;
}
