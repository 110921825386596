.file-dropzone {
    border: dashed 2px #c4cdd6; //alloy
    height:  200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    display: flex;
    justify-content: space-around;
    background: #edf3f8; //horizon
    &.file-drag-over {
        border: dashed 2px #354259; //granite.darker
        * {
            pointer-events: none;
        }
    }
}

.file-dropzone-wrapper {
    width: 100%;
}

small.has-error.help-block {
    color: #d24a35;
}
