$card-margin: 5px;

.card-container {
    border: 1px solid #c4cdd6;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: initial;
    width: 360px;
    padding: 12px 5px;
    margin: 10px $card-margin;
    .preview-area {
        border-bottom: 1px solid #c4cdd6;
        position: relative;

        .image-container {
            height: 135px;
            background-repeat: no-repeat;
            background-position: center;
        }

        .inline-preview {
            padding: 20px;
            overflow: hidden;
            word-break: break-word;
        }
    }
    .font-details>div {
        padding-bottom: 5px;
    }

    .font-details {
        padding: 0 10px;
        .font-details__name {
            padding: 12px 0 12px 0;
            font-weight: bold;
            font-size: 20px;
            color: #808080;
        }
        .font-details__repo {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .font-details__style {
            font-weight: 800;
        }
    }
    .font-footer {
        display: flex;
        flex-direction: row-reverse;
        font-weight: bold;
    }
}
.card-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.font-actions {
    display: flex;
    margin: -12px -5px;
    padding-bottom: 20px;
    flex-direction: row-reverse;
    min-height: 50px;;
    .btn.btn-default, .btn.btn-default:hover, .btn.btn-default:focus, .btn.btn-default:active {
        border: none;
        outline: none;
    }
    .btn {
        padding: 6px 10px;
        &.btn-default:active:hover {
            border: none;
        }
    }
}

.card-loader {
    position: absolute;
    left: calc(50% - 36px);
    top: calc(50% - 36px);
}

@media (min-width: 500px) {
    .card-container {
        width:calc(50% - 2*#{$card-margin});
    }
}

@media (min-width: 1000px) {
    .card-container {
        width:calc(33.33% - 2*#{$card-margin});
    }
}

@media (min-width: 1300px) {
    .card-container {
        width:calc(25% - 2*#{$card-margin});
    }
}

@media (min-width: 1600px) {
    .card-container {
        width:calc(20% -2*#{$card-margin});
    }
}

.deleted-background {
    background: #e9e9e9;
}

.deleted-background-details {
    background: #e9e9e9;
    padding-top: 40px;
}
