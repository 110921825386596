.file-upload {
    margin: 10px 0;

    .hidden-file-input {
        height: calc(100% - 10px);
        width: calc(100% - 10px);
        position: absolute;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
    }
    .hidden-file-input::-webkit-file-upload-button {
        visibility: hidden;
    }
}

.file-upload__selected-file {
    padding: 10px;
    border-radius: 6px;
    background-color: #354259;
    color: #fff;
    width: 100%;

    .file-upload__file-name {
        font-size: 12px;
        font-weight: bold;
    }

    .file-upload__file-size {
        font-size: 10px;
    }

    button.close {
        color: #fff;
        font-size: 20px;
    }
}
