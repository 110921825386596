.create-repository {
    .modal-body {
        padding: 10px 20px 0 20px;
        .create-repository__intro {
            margin-bottom: 10px;
        }
    }
}

.edit-repository {
    display: flex;
    flex-basis: 25%;
    .btn {
        padding-left: 10px;
        padding-right: 10px;
    }
    .edit-repo__name {
        line-height: 48px;
        width: 250px;
        overflow: hidden;
        border: 1px solid #ccc;
        max-width: 290px;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 15px;
    }
    .edit-repo__text-box {
        width: 250px;
    }
}

.repository-info {
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .card-block {
        width: 800px;
        padding: 50px;
        div {
            width: 100%;
            margin: 20px;
        }
        div:last-of-type {
            text-align: center;
        }
        .repository-message {
            text-align: center;
            span {
                font-size: 24px;
            }
        }
    }
}

.repository-info-hidden {
    display: none;
}

.repository_btn-back {
    position: relative;
    width: 350px;
    height: 50px;
    border: 1px solid red;
    display: flex;
    justify-content: space-between;
}

.repository_btn-back.btn {
    text-align: left;
    padding: 15px 16px;
}
.repository {
    .dropdown-toggle span.caret {
        right: 10px;
        position: absolute;
    }
    .btn.dropdown-toggle {
        overflow: hidden;
        max-width: 350px;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 30px;
    }
    .ij-icon.ij-icon-lg {
        right: 10px;
    }
}
.edit-repo.tooltip {
    word-break: break-word;
}
